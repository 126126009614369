import * as React from "react";

interface Props {
    id: string;
    primarySlot: React.ReactNode;
    secondarySlot: React.ReactNode;
    reverseOrder?: boolean;
}

const SplitSection = (props: Props) => {
    const {
        id,
        primarySlot,
        secondarySlot,
        reverseOrder = false,
    } = props;

    return (
        <section id={id} className="py-20">
            <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row">
                <div className="lg:w-1/2">{primarySlot}</div>
                <div className={`mt-10 lg:mt-0 w-full lg:w-1/2 ${reverseOrder && `order-last lg:order-first`}`}>
                    {secondarySlot}
                </div>
            </div>
        </section>
    );
};

export default SplitSection;
