import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react"
import { Helmet } from "react-helmet";

import Button from "../components/Button";
import Layout from "../components/layout/Layout";
import SplitSection from "../components/SplitSection";

const IndexPage = () => {
    return (
        <Layout>
            <Helmet>
                <title>Welcome</title>
                <meta name="description" content="Introduction to Eborsoft services."></meta>
            </Helmet>
            <section className="pt-20 md:pt-35 lg:pb-40">
                <div className="container mx-auto px-8 lg:flex">
                    <div className="text-center lg:text-left lg:w-1/2">
                        <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                            Bespoke software systems design and development
                        </h1>
                        <p className="text-xl lg:text-2xl mt-6 font-light">
                            From brand new greenfield projects to re-engineering existing legacy systems, Eborsoft has the expertise to help.
                        </p>
                        <p className="mt-8 md:mt-12">
                            <Button onClick={() => navigate("/services")} size="lg">Find Out More</Button>
                        </p>
                        <p className="mt-4 text-gray-600">Get in touch to discuss the needs of your business.</p>
                    </div>
                    <div className="lg:w-1/2">
                        <StaticImage src="../images/hero.png" alt="Intro image" />
                    </div>
                </div>
            </section>

            <SplitSection
                id="development"
                primarySlot={
                    <div className="lg:pr-32 xl:pr-48">
                        <h3 className="text-3xl font-semibold leading-tight">Application Development</h3>
                        <p className="mt-8 text-xl font-light leading-relaxed">
                            Whether it's internal administrative systems or mobile applications running on the
                            latest versions of iOS and Android, Eborsoft can work with you to help plan
                            and develop your requirements into finished products quickly.
                        </p>
                    </div>
                }
                secondarySlot={<StaticImage src="../images/development.png" alt="Development" />}
            />

            <SplitSection
                id="reengineering"
                reverseOrder={true}
                primarySlot={
                    <div className="lg:pr-22 xl:pr-28">
                        <h3 className="text-3xl font-semibold leading-tight">Legacy System Re-engineering</h3>
                        <p className="mt-8 text-xl font-light leading-relaxed">
                            Over <strong>85% of businesses</strong> have legacy systems in place which may be causing
                            inefficiencies in technology adoption, high costs and employee/customer experience issues.
                            The cost of keeping old systems is not only a support problem, but may increasingly affect
                            the security posture of an organisation.
                        </p>
                    </div>
                }
                secondarySlot={
                    <div className="lg:pr-32 xl:pr-48">
                        <StaticImage src="../images/broken.png" alt="Development" />
                    </div>
                }
            />
        </Layout>
    )
}

export default IndexPage
